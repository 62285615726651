import Main from '@/components/main/main.vue'
import Content from '@/components/content/content.vue'
// import Setting from '@/views/setting/setting.vue'

export default [
	{
		path: '/',
		name: 'main',
		redirect: '/member/member-data',
		component: Main,
		children: [
			{
				path: '/setting',
				name: 'setting',
				component: Content,
				hideInMenu: false,
				meta: {
					title: '设置'
				},
				children: [
					{
						path: '',
						name: '',
						meta: {
							access: [],
							title: '系统设置',
							icon: 'md-document'
						},
						component: () => import('@/views/setting/setting.vue')
					}
				]
			},
			{
				path: '/member',
				name: 'member',
				component: Content,
				meta: {
					title: '会员'
				},
				children: [
					{
						path: 'managephone-task-list',
						name: 'managephone-task-list',
						meta: {
							access: [],
							title: 'managephone-task-list',
							icon: 'md-build'
						},
						component: () => import('@/views/member/manage-phone-task-list')
					},
					{
						path: 'search-vpphone-list',
						name: 'search-vpphone-list',
						meta: {
							access: [],
							title: 'search-vpphone-list',
							icon: 'md-build'
						},
						component: () => import('@/views/member/search-group-call-list')
					},
					{
						path: 'vpphone-list',
						name: 'vpphone-list',
						meta: {
							access: [],
							title: 'vpphone-list',
							icon: 'md-build'
						},
						component: () => import('@/views/member/group-call-list')
					},
					{
						path: 'channel-management',
						name: 'channel-management',
						meta: {
							access: [],
							title: '渠道管理',
							icon: 'md-build'
						},
						component: () => import('@/views/channel-management')
					},
					// 电销数据
					{
						path: 'sale-data',
						name: 'sale-data',
						meta: {
							access: [],
							title: '电销数据',
							icon: 'md-build'
						},
						component: () => import('@/views/member/sale-data')
					},
					//新加业绩管理
					{
						path: 'achievement-manage',
						name: 'achievement-manage',
						meta: {
							access: [],
							title: '业绩管理',
							icon: 'md-build'
						},
						component: () => import('@/views/member/achievement-manage')
					},
					//新加专员数据统计
					{
						path: 'commissioner-statistics',
						name: 'commissioner-statistics',
						meta: {
							access: [],
							title: '专员数据统计',
							icon: 'md-build'
						},
						component: () => import('@/views/member/commissioner-statistics')
					},
					{
						path: 'import-members',
						name: 'import-members',
						meta: {
							access: [],
							title: '导入会员',
							icon: 'md-build'
						},
						component: () => import('@/views/member/import-members')
					},
					{
						path: 'number-import',
						name: 'number-import',
						meta: {
							access: [],
							title: '号码导入',
							icon: 'md-document'
						},
						component: () => import('@/views/number/number-import')
					},
					{
						path: 'change-number',
						name: 'change-number',
						meta: {
							access: [],
							title: '更换手机申请',
							icon: 'md-document'
						},
						component: () => import('@/views/number/change-number')
					},
					{
						path: 'group-call',
						name: 'group-call',
						meta: {
							access: [],
							title: '群呼任务',
							icon: 'md-document'
						},
						component: () => import('@/views/member/group-call')
					},
					{
						path: 'member-target',
						name: 'member-target',
						meta: {
							access: [],
							title: '目标会员',
							icon: 'md-document'
						},
						component: () => import('@/views/member/member-target')
					},
					{
						path: 'member-data',
						name: 'member-data',
						meta: {
							access: [],
							title: '会员资料',
							icon: 'md-document'
						},
						component: () => import('@/views/member/member-data')
					},
					{
						path: 'member-pullback',
						name: 'member-pullback',
						meta: {
							access: [],
							title: '会员拉回',
							icon: 'md-document'
						},
						component: () => import('@/views/member/member-pullback')
					},
					{
						path: 'member-data-customer',
						name: 'member-data-customer',
						meta: {
							access: [],
							title: '客服会员',
							icon: 'md-document'
						},
						component: () => import('@/views/member/member-data-customer')
					},
					{
						path: 'member-list',
						name: 'member-list',
						meta: {
							access: [],
							title: '会员列表',
							icon: 'md-document'
						},
						component: () => import('@/views/member/member-list')
					},
					{
						path: 'agents-list',
						name: 'agents-list',
						meta: {
							access: [],
							title: '代理商列表',
							icon: 'md-document'
						},
						component: () => import('@/views/member/agents-list')
					},
					{
						path: 'agent-detail',
						name: 'agent-detail',
						meta: {
							access: [],
							title: '代理资料',
							icon: 'md-document'
						},
						component: () => import('@/views/member/agent-detail')
					},
					{
						path: 'intended-member',
						name: 'intended-member',
						meta: {
							access: [],
							title: '意向会员',
							icon: 'md-document'
						},
						component: () => import('@/views/member/intended-member')
					},
					// {
					// 	path: 'member-control',
					// 	name: 'member-control',
					// 	meta: {
					// 		access: [],
					// 		title: '操作台',
					// 		icon: 'md-document'
					// 	},
					// 	component: () => import('@/views/member/member-control')
					// },
					{
						path: 'member-control_new',
						name: 'member-control_new',
						meta: {
							access: [],
							title: '操作台',
							icon: 'md-document'
						},
						component: () => import('@/views/member/member-control_new')
					},
					{
						path: 'view-work-records',
						name: 'view-work-records',
						meta: {
							access: [],
							title: '查看工作记录',
							icon: 'md-document'
						},
						component: () => import('@/views/member/view-work-records')
					},
					{
						path: 'member-appeal',
						name: 'member-appeal',
						meta: {
							access: [],
							title: '申诉列表',
							icon: 'md-document'
						},
						component: () => import('@/views/member/member-appeal')
					},
					{
						path: 'member-active',
						name: 'member-active',
						meta: {
							access: [],
							title: '活动码激活',
							icon: 'md-document'
						},
						component: () => import('@/views/member/member-active')
					},
					{
						path: 'duplicate-member',
						name: 'duplicate-member',
						meta: {
							access: [],
							title: '重复会员',
							icon: 'md-document'
						},
						component: () => import('@/views/member/duplicate-member')
					},
					{
						path: 'duplicate-detail',
						name: 'duplicate-detail',
						meta: {
							access: [],
							title: '重复会员详情',
							icon: 'md-document'
						},
						component: () => import('@/views/member/duplicate-detail')
					},
					{
						path: 'member-level',
						name: 'member-level',
						meta: {
							access: [],
							title: '会员等级',
							icon: 'md-document'
						},
						component: () => import('@/views/member/member-level')
					},
					{
						path: 'member-vip-level',
						name: 'member-vip-level',
						meta: {
							access: [],
							title: '会员层级',
							icon: 'md-document'
						},
						component: () => import('@/views/member/member-vip-level')
					},
					{
						path: 'member-note',
						name: 'member-note',
						meta: {
							access: [],
							title: '会员笔记',
							icon: 'md-chatbubbles'
						},
						component: () => import('@/views/member/member-note')
					},
					{
						path: 'proxy-list',
						name: 'proxy-list',
						meta: {
							access: [],
							title: '代理列表',
							icon: 'md-git-network'
						},
						component: () => import('@/views/member/proxy-list')
					},
					{
						path: 'roster-manage',
						name: 'roster-manage',
						meta: {
							access: [],
							title: '名单管理',
							icon: 'md-git-network'
						},
						component: () => import('@/views/member/roster-manage')
					}
				]
			},
			{
				path: '/record',
				name: 'record',
				component: Content,
				meta: {
					title: '记录'
				},
				children: [
					{
						path: 'commissioner-call-record',
						name: 'commissioner-call-record',
						meta: {
							access: [],
							title: '专员通话总览',
							icon: 'ios-call'
						},
						component: () => import('@/views/record/commissioner-call-record')
					},
					{
						path: 'sale-record',
						name: 'sale-record',
						meta: {
							access: [],
							title: '通话记录',
							icon: 'ios-call'
						},
						component: () => import('@/views/record/sale-record')
					},
					{
						path: 'msg-record',
						name: 'msg-record',
						meta: {
							access: [],
							title: '短信记录',
							icon: 'md-mail'
						},
						component: () => import('@/views/record/msg-record')
					},
					{
						path: 'action-record',
						name: 'action-record',
						meta: {
							access: [],
							title: '操作记录',
							icon: 'md-build'
						},
						component: () => import('@/views/record/action-record')
					},
					{
						path: 'recharge-record',
						name: 'recharge-record',
						meta: {
							access: [],
							title: '充值记录',
							icon: 'md-build'
						},
						component: () => import('@/views/record/recharge-record')
					},
					{
						path: 'transfer-record',
						name: 'transfer-record',
						meta: {
							access: [],
							title: '转账记录',
							icon: 'md-build'
						},
						component: () => import('@/views/record/transfer-record')
					},
					{
						path: 'login-record',
						name: 'login-record',
						meta: {
							access: [],
							title: '登录日志',
							icon: 'md-build'
						},
						component: () => import('@/views/record/login-record')
					},
					{
						path: 'test-call',
						name: 'test-call',
						meta: {
							title: '测试拨打',
							access: [],
							icon: 'md-git-network'
						},
						component: () => import('@/views/member/test-call')
					},
					{
						path: 'intention-specialist',
						name: 'intention-specialist',
						meta: {
							title: '电销个人看板',
							access: [],
							icon: 'md-git-network'
						},
						component: () => import('@/views/member/intention-specialist')
					},
					{
						path: 'wacontact-record',
						name: 'wacontact-record',
						meta: {
							access: [],
							title: 'OA通讯录',
							icon: 'md-build'
						},
						component: () => import('@/views/record/wacontact-record')
					},
				]
			},
			{
				path: '/group',
				name: 'group',
				component: Content,
				meta: {
					title: '团队'
				},
				children: [
					{
						path: 'organization-architecture',
						name: 'organization-architecture',
						meta: {
							access: ['departments.index'],
							title: '组织架构',
							icon: 'md-git-network'
						},
						component: () => import('@/views/group/organization-architecture')
					},
					{
						path: 'permission-manage',
						name: 'permission-manage',
						meta: {
							access: ['permission_manage.index'],
							title: '权限管理',
							icon: 'md-git-network'
						},
						component: () => import('@/views/group/permission-manage')
					},
					{
						path: 'person-manage',
						name: 'person-manage',
						meta: {
							access: ['managers.index'],
							title: '人员管理',
							icon: 'md-person'
						},
						component: () => import('@/views/group/person-manage')
					},
					{
						path: 'social-software',
						name: 'social-software',
						meta: {
							access: ['ims.index'],
							title: '社交软件',
							icon: 'logo-codepen'
						},
						component: () => import('@/views/group/social-software')
					},
					{
						path: 'sms-channel',
						name: 'sms-channel',
						meta: {
							title: 'SMS渠道配置',
							icon: 'md-chatbubbles'
						},
						component: () => import('@/views/group/sms-channel')
					},
					{
						path: 'sms-template',
						name: 'sms-template',
						meta: {
							title: 'SMS模板',
							icon: 'md-chatbubbles'
						},
						component: () => import('@/views/group/sms-template')
					},
					{
						path: 'manager-user',
						name: 'manager-user',
						meta: {
							access: ['manageruser.index'],
							title: '专员会员关系管理',
							icon: 'md-person'
						},
						component: () => import('@/views/group/manager-user')
					}
				]
			},
			{
				path: '/report',
				name: 'report',
				component: Content,
				meta: {
					title: '报表'
				},
				children: [
					{
						path: 'new-register',
						name: 'new-register',
						meta: {
							access: [],
							title: '新注册用户报表',
							icon: 'md-git-network'
						},
						component: () => import('@/views/report/new-register')
					},
					{
						path: 'call-statistics-report',
						name: 'call-statistics-report',
						meta: {
							access: [],
							title: '通话统计看板',
							icon: 'md-git-network'
						},
						component: () => import('@/views/report/call-statistics-report')
					},
					{
						path: 'call-report',
						name: 'call-report',
						meta: {
							access: [],
							title: '通话报表',
							icon: 'md-git-network'
						},
						component: () => import('@/views/report/call-report')
					},
					{
						path: 'sms-report',
						name: 'sms-report',
						meta: {
							access: [],
							title: '短信报表',
							icon: 'md-git-network'
						},
						component: () => import('@/views/report/sms-report')
					},
					{
						path: 'comprehensive-chart',
						name: 'comprehensive-chart',
						meta: {
							access: [],
							title: '综合报表',
							icon: 'md-git-network'
						},
						component: () => import('@/views/report/comprehensive-chart')
					}
				]
			},
			{
				path: '/pay',
				name: 'pay',
				component: Content,
				meta: {
					title: '支付'
				},
				children: [
					{
						path: '/pay-recharge',
						name: 'pay-recharge',
						meta: {
							access: [],
							title: '充值',
							icon: 'md-git-network'
						},
						component: () => import('@/views/pay/recharge')
					},
					{
						path: '/pay-wallet',
						name: 'pay-wallet',
						meta: {
							access: [],
							title: '钱包',
							icon: 'md-git-network'
						},
						component: () => import('@/views/pay/wallet')
					}
				]
			}
		]
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login/login')
	},
	{
		path: '*',
		redirect: '/'
	}
]
