<template>
	<div class="ele-head">
		<div class="ele-head-title">
			<div style="width: 240px">
				<span class="ele-head-title-logo">{{ systemName }} </span>
			</div>
		</div>
		<div class="ele-head-content"></div>
		<div class="ele-head-info">
			<div class="ele-head-info-item" style="margin-right: 20px;">
				<Button v-show="isShowReminder" slot="extra" type="success"
						style="height: 30px;width: 80px;" @click="checkMangerReminder()">{{
							$t('提醒')
						}}</Button>
			</div>

			<!-- 用户信息 -->
			<language @on-lang-change="setLocal" style="margin-right: 10px" :lang="local" />
			<div>
				<Avatar src="https://i.loli.net/2017/08/21/599a521472424.jpg" />
				<Poptip width="300">
					<span style="font-size: 14px; vertical-align: middle; margin-left: 8px; color: #ffffff" class="cur">{{ userName }}</span>
					<Icon class="cur" type="md-arrow-dropdown" size="22" color="#ffffff" />
					<div slot="content">
						<div>
							<div style="display: flex; justify-content: space-between; padding: 12px">
								<div>
									<Avatar src="https://i.loli.net/2017/08/21/599a521472424.jpg" />
									<span style="font-size: 14px; vertical-align: middle; margin-left: 8px">{{ userName }}</span>
								</div>
								<Button size="small" shape="circle" @click="handleUpdatePassword">{{ $t('修改密码') }}</Button>
							</div>
							<Divider />
							<ul>
								<!-- <li style="height:40px;padding-left:12px;line-height:30px">
                  <div style="display:flex;justify-content: space-between;" v-if="rechargeRight || walletRight">
                    <div>
                      <Icon custom="iconfont icon-qianbao-" size="20" />
                      <Icon type="logo-yen" style="margin-left:8px"/>
                      <span style="vertical-align: middle;font-size:13px;margin-left:5px;">{{balance}}</span>
                    </div>
                    <div>
                      <Button type="text" @click="turnTo('/pay-recharge')" v-if="rechargeRight">充值</Button>
                      <Button type="text" @click="turnTo('/pay-wallet')" v-if="walletRight">钱包</Button>
                    </div>
                  </div>
                </li>
                <Divider /> -->
								<li style="height: 40px; padding-left: 12px; line-height: 30px">
									<!-- <i class="iconfont icon-ip"></i> -->
									<Icon custom="iconfont icon-ip" size="20" />
									<span style="vertical-align: middle; font-size: 13px; margin-left: 5px">{{ login_ip }}</span>
								</li>
								<Divider />
								<li style="height: 40px; padding-left: 12px; line-height: 30px">
									<!-- <i class="iconfont icon-shijian"></i> -->
									<Icon type="ios-time-outline" size="20" />
									<span style="vertical-align: middle; font-size: 13px; margin-left: 5px">{{ login_time }}</span>
								</li>
								<Divider />
								<li style="height: 50px; padding-left: 12px; line-height: 40px" class="cur" @click="logout">
									<Icon type="ios-power" size="20" />
									<span style="vertical-align: middle; font-size: 13px; margin-left: 5px">
										{{ $t('退出登录') }}
									</span>
								</li>
							</ul>
						</div>
					</div>
				</Poptip>
				<!-- <Icon type="md-help-circle" size="20" style="margin-left:12px;" color="#ffffff"/> -->
				<Icon class="cur" type="md-download" size="20" style="margin: 0 15px" color="#ffffff" @click="handleCheckDown()" :title="$t('下载列表')" />
				<Icon class="cur" type="ios-settings" size="20" color="#ffffff" @click="turnTo('/setting')" title="设置" />
				<Poptip placement="bottom-end">
					<Icon type="ios-bug" size="20" style="margin-left: 15px" :color="reptileColor" class="cur" @click="getReptileList" />
					<div slot="content">
						<div style="position: relative; min-height: 150px; width: 230px">
							<Spin fix v-show="spinShow"></Spin>
							<ul v-show="!spinShow" style="padding: 8px 0; width: 230px">
								<li class="reptile-items" v-for="(item, index) in reptileList" :key="index" style="display: flex; justify-content: space-between">
									<span>{{ item.username }}</span>
									<span :class="{ greenColor: item.status === 0, errorColor: item.status === 1, warningColor: item.status === 2 }">{{
										item.status === 0 ? $t('正常') : item.status === 1 ? $t('错误') : $t('未登陆')
									}}</span>
								</li>
								<Divider />
								<li style="display: flex; justify-content: space-between; align-items: center; padding-left: 16px">
									<div>
										<Icon type="md-refresh" @click="getReptileList" size="20" :title="$t('刷新')" class="cur" />
									</div>
									<Button type="text" @click="openOtpModal">OTP</Button>
								</li>
							</ul>
						</div>
					</div>
				</Poptip>
			</div>
		</div>
		<Drawer :title="$t('下载列表')" v-model="isDownModal" width="720" :styles="styles">
			<ul class="demo-drawer-body">
				<li v-for="(item, index) in downloadList" :key="index">
					<span>#{{ index + 1 }}</span>
					<span class="file-type">{{ fileType[item.type] }}</span>
					<span :class="colorType[item.status]">{{ exportStatus[item.status] }}</span>
					<span>{{ item.created_at }}</span>
					<span v-if="item.status === 1" class="primaryColor cur" @click="handleDownload(item.url)">{{ $t('下载') }}</span>
					<span v-else-if="item.status === 0">{{ $t('请稍候...') }}</span>
					<span v-else class="primaryColor cur" @click="changeDownStatus(item.id, index)">{{ $t('重启') }}</span>
				</li>
			</ul>
			<div class="demo-drawer-footer">
				<Page
					:total="pagination.total"
					size="small"
					:current.sync="pagination.current_page"
					:page-size.sync="pagination.per_page"
					@on-change="changePage"
					@on-page-size-change="changeSize"
					show-sizer
					show-elevatorz
					show-total
				/>
			</div>
		</Drawer>
		<!-- 修改密码 -->
		<Modal v-model="isPswmodal" width="500" :closable="true" :rules="ruleValidate" :mask-closable="false">
			<p slot="header" style="text-align: center">
				<span>{{ $t('修改密码') }}</span>
			</p>
			<div style="text-align: center">
				<Form ref="formItem" :model="formItem" :rules="ruleValidate" :label-width="85">
					<FormItem :label="$t('旧密码')" prop="current_pass">
						<Input v-model="formItem.current_pass" type="password" :v-html="$t('请输入旧密码')" />
					</FormItem>
					<FormItem :label="$t('新密码')" prop="password">
						<Input v-model="formItem.password" type="password" :v-html="$t('请输入新密码')" />
					</FormItem>
					<FormItem :label="$t('确认新密码')" prop="password_confirmation">
						<Input v-model="formItem.password_confirmation" type="password" :v-html="$t('请再次输入新密码')" />
					</FormItem>
				</Form>
			</div>
			<div slot="footer" style="display: flex; justify-content: space-around">
				<Button @click="isPswmodal = false">{{ $t('取消') }}</Button>
				<Button type="primary" @click="handleSubmit">{{ $t('确定') }}</Button>
			</div>
		</Modal>
		<!-- 输入OTP -->
		<Modal v-model="otpVisible">
			<p slot="header" style="text-align: center">
				<span>OTP</span>
			</p>
			<div>
				<Form :model="createOtp" ref="createOtp" :rules="ruleOtp">
					<FormItem :label="$t('用户名')" prop="account_id">
						<Select v-model="createOtp.account_id" :placeholder="$t('请选择')">
							<Option :disabled="item.status !== 2" :value="item.account_id" v-for="(item, index) in reptileList" :key="index">{{ item.username }}</Option>
						</Select>
					</FormItem>
					<FormItem label="OTP" prop="otp">
						<Input :placeholder="$t('请输入OTP')" v-model="createOtp.otp" />
					</FormItem>
				</Form>
			</div>
			<div slot="footer" style="display: flex; justify-content: space-around">
				<Button @click="otpVisible = false">{{ $t('取消') }}</Button>
				<Button type="primary" @click="handleSubmitOtp">{{ $t('确定') }}</Button>
			</div>
		</Modal>
		<Drawer :title="$t('提醒面板')" v-model="isShowRemainderDrawer" width="830">
			<managerRemainder :data.sync="managerRemainder" />
		</Drawer>
	</div>
</template>
<script>
import { removeToken, hasOneOf } from '@/libs/utils'
import Language from '../language'
// import { clearOtpLunCheck } from '@/libs/otp'
import User from '@/api/user'
import config from '@/config'
import { mapMutations } from 'vuex'
import managerRemainder from '../../views/member/manager-reminder'
export default {
	components: {
		Language, managerRemainder
	},
	data() {
		const validatePassCheck = (rule, value, callback) => {
			if (value === '') {
				callback(new Error(this.$t('请再次输入新密码')))
			} else if (value !== this.formItem.password) {
				callback(new Error(this.$t('两次输入密码不一致!')))
			} else {
				callback()
			}
		}
		return {
			otpVisible: false,
			spinShow: true,
			imageUrl: 'static/icon.ico',
			isPswmodal: false,
			isLoadmodal: false,
			isDownModal: false,
			styles: {
				height: 'calc(~"100% - 55px")',
				overflow: 'auto',
				paddingBottom: '53px',
				position: 'static'
			},
			createOtp: {
				account_id: '',
				otp: ''
			},
			ruleOtp: {
				account_id: [{ required: true, type: 'number', message: this.$t('用户名不能为空'), trigger: 'change' }],
				otp: [{ required: true, message: this.$t('OTP不能为空'), trigger: 'blur' }]
			},
			downloadList: [],
			pagination: {
				current_page: 1,
				per_page: 10,
				total: 0
			},
			downloadPercent: 0,
			formItem: {
				current_pass: '',
				password: '',
				password_confirmation: ''
			},
			ruleValidate: {
				current_pass: [
					{ required: true, message: this.$t('旧密码不能为空'), trigger: 'blur' },
					{ pattern: /^[A-Za-z0-9]+$/, message: this.$t('密码仅可输入数字、大小写字母'), trigger: 'blur' },
					{ type: 'string', min: 6, max: 20, message: this.$t('密码至少6位,至多20位'), trigger: 'blur' }
				],
				password: [
					{ required: true, message: this.$t('新密码不能为空'), trigger: 'blur' },
					{ pattern: /^[A-Za-z0-9_]+$/, message: this.$t('密码仅可输入数字、大小写字母和下划线_'), trigger: 'blur' },
					{ type: 'string', min: 6, max: 20, message: this.$t('密码至少6位,至多20位'), trigger: 'blur' }
				],
				password_confirmation: [{ required: true, validator: validatePassCheck, trigger: 'blur' }]
			},
			fileType: {
				fs_user_export: this.$t('会员资料'),
				telemarketing_export: this.$t('统计数据导出'),
				dial_log_export: this.$t('通话记录'),
				report_newer_export: this.$t('新注册用户'),
				fs_agent_export: this.$t('代理列表'),
				report_dial_export: this.$t('通话报表'),
				report_sms_export: this.$t('短信报表'),
				user_package_export: this.$t('名单数据'),
				channel_export: '渠道列表数据',
				number_export_all: '号码导入数据',
				number_export: '号码导入失败数据',
				datadownload_export_all: this.$t('数据下载详情导出'),
				work_records_export: this.$t('工作记录导出'),
				manager_records_export: this.$t('维护记录导出')
			},
			exportStatus: [this.$t('正在导出'), this.$t('导出成功'), this.$t('导出失败')],
			colorType: ['warningColor', 'successColor', 'errorColor'],
			otpValue: null,
			isShowRemainderDrawer: false,
			managerRemainder: [],
		}
	},
	computed: {
		access() {
			return this.$store.state.user.access
		},
		rechargeRight() {
			// 充值
			return hasOneOf(['pays.get_order'], this.access)
		},
		walletRight() {
			// 钱包
			return hasOneOf(['pays.get_order'], this.access)
		},
		userName() {
			return this.$store.state.user.name
		},
		login_ip() {
			return this.$store.state.user.login_ip
		},
		login_time() {
			return this.$store.state.user.login_time
		},
		balance() {
			return this.$store.state.user.balance
		},
		systemName() {
			let code = localStorage.getItem('code')
			return config.baseUrl.pro[code].name
		},
		reptileList() {
			return this.$store.state.user.reptileList
		},
		showOtpBtn() {
			let flag = this.reptileList.some((e) => e.status !== 0)
			return flag
		},
		reptileColor() {
			let reptile = this.reptileList.map((e) => e.status)
			if (reptile.includes(1)) {
				return '#ed4014'
			} else if (reptile.includes(2)) {
				return '#ff9900'
			} else {
				return '#19be6b'
			}
		},
		isGbk() {
			return this.$store.state.user.isGbk
		},
		local() {
			return this.$store.state.user.local
		},
		isShowReminder() {
			const code = localStorage.getItem('code')
			const arrList = ['00034', '00038', '00001', '00047', '00044']
			return arrList.includes(code)
		},
	},
	created() {},
	methods: {
		...mapMutations(['setLocal']),
		turnTo(path) {
			this.$router.push(path)
		},
		logout() {
			// clearOtpLunCheck()
			removeToken()
			this.$router.push('/login')
		},
		async handleCheckDown(page = 1, per_page = 10) {
			this.isDownModal = true
			let params = { page, per_page }
			const result = await User.queryDownloadList(params)
			this.downloadList = result.data
			this.pagination.current_page = result.meta.current_page
			this.pagination.per_page = Number(result.meta.per_page)
			this.pagination.total = result.meta.total
		},
		changePage() {
			this.handleCheckDown(this.pagination.current_page, this.pagination.per_page)
		},
		changeSize(size) {
			this.pagination.per_page = size
			this.changePage()
		},
		// 处理下载文件
		handleDownload(url) {
			window.open(url)
		},
		// 重启下载任务
		async changeDownStatus(id, index) {
			let params = { status: 0 }
			const result = await User.changeDownStatus(id, params)
			this.$set(this.downloadList, index, result.data)
		},
		handleUpdateLater() {
			this.isLoadmodal = false
			this.$Message.info({
				content: this.$t('关闭程序后,自动更新'),
				duration: 5
			})
		},
		handleUpdatePassword() {
			this.isPswmodal = true
			this.$refs.formItem.resetFields()
		},
		// 确认修改密码
		async updateUserPassword() {
			const result = await User.updateUserPassword(this.formItem)
			this.isPswmodal = false
			if (result.code === 200) {
				this.$Message.success(this.$t('密码修改成功'))
			}
		},
		handleSubmit() {
			this.$refs.formItem.validate((valid) => {
				if (valid) {
					this.updateUserPassword()
				} else {
					this.$Message.error(this.$t('请按要求填写表单信息!'))
				}
			})
		},
		// 获取爬虫列表
		async getReptileList() {
			this.spinShow = true
			await this.$store.dispatch('getReptileList')
			this.spinShow = false
		},
		// 打开otp输入框
		openOtpModal() {
			this.otpVisible = true
			this.$refs.createOtp.resetFields()
		},
		handleSubmitOtp() {
			this.$refs.createOtp.validate((valid) => {
				if (valid) {
					this.otpVisible = false
					this.modifyOtpValue()
				} else {
					this.$Message.error('请按要求填写表单!')
				}
			})
		},
		async modifyOtpValue() {
			let data = this.createOtp
			const result = await User.modifyOtpValue(data)
			if (result.code === 200) {
				this.$Message.success(this.$t('修改成功'))
				this.otpValue = null
				this.$store.dispatch('getReptileList')
			}
		},
		async checkMangerReminder() {
			this.isShowRemainderDrawer = true
			this.requestNotificationPermission();
		},
		requestNotificationPermission() {
			if (Notification.permission !== 'granted') {
				Notification.requestPermission()
					.then();
				this.$Notice.info({
					title: this.$t('请允许通知'),
					desc: this.$t('请把浏览器通知权限设置为允许,并刷新页面'),
					duration: 0
				});
			}
		},
	},
	mounted() {
		this.setLocal(this.$i18n.locale)
	}
}
</script>
<style lang="less" scoped>
.ele-head {
	width: 100%;
	height: 60px;
	line-height: 60px;
	display: flex;
	justify-content: space-between;
	padding: 0 20px;
	background-color: #2d8cf0;
	// -webkit-app-region: drag;
	&-title {
		// width: 300px;
		height: 60px;
		display: flex;
		-webkit-app-region: no-drag;
		div {
			text-align: center;
			img {
				vertical-align: middle;
			}
		}
		&-logo {
			font-size: 18px;
			color: #ffffff;
		}
	}
	&-content {
		flex: 1;
		-webkit-app-region: drag;
	}
	&-info {
		-webkit-app-region: no-drag;
		display: flex;
		// align-items: center;
		&-line {
			width: 1px;
			height: 20px;
			border-left: 0.5px solid #ffffff;
			opacity: 0.5;
			margin: 0 20px;
			margin-top: 20px;
			// transform: scaleX(0.5);
			// transform-origin: 50% 100%;
		}
	}
}
.demo-drawer-body {
	width: 100%;
	li {
		width: 100%;
		display: flex;
		height: 50px;
		border-bottom: 1px solid #e8eaec;
		margin-bottom: 10px;
		justify-content: space-between;
		align-items: center;
		span {
			display: inline-block;
			width: 20%;
		}
		span:last-child {
			text-align: right;
		}
	}
}
.greenColor {
	color: #19be6b;
}
.warningColor {
	color: #ff9900;
}
.errorColor {
	color: #ed4014;
}

.reptile-items {
	padding: 10px 16px;
}
.demo-drawer-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 1px solid #e8e8e8;
	padding: 10px 16px;
	text-align: right;
	background: #fff;
}
:deep(.ivu-poptip-body) {
	padding: 0px !important;
}
:deep(.ivu-divider-horizontal) {
	margin: 0;
	margin-bottom: 8px;
}
:deep(.ivu-poptip-popper) {
	top: 51px !important;
}
</style>
