<template>
	<div class="reminder-details">
		<div>
			<div>
				<h2>{{ $t('提醒记录') }}</h2>
				<i-table :loading="loading" :columns="columns" :data="tableData" border style="margin-top: 10px">
					<template #action="{ index,row }">
						<Button v-if="row.is_done == 0" type="primary" size="small" @click="handleDone(row)"> {{ $t('完成') }}
						</Button>
						<Button v-if="row.is_done == 0" style="margin-left: 10px;" type="primary" size="small" @click="handleUpdate(row)"> {{ $t('编辑') }}
						</Button>
						<Button v-if="row.is_done == 1 || row.status_text == '已逾期'" style="margin-left: 10px;" type="error" size="small" @click="handleReactive(row)"> {{ $t('重新激活') }}
						</Button>
						<Button v-if="row.is_delete == 0" style="margin-left: 10px;" type="error" size="small" @click="handleDelete(index,row)"> {{ $t('删除') }}
						</Button>
					</template>
				</i-table>
				<Page style="text-align: right; margin-top: 10px" show-elevator show-sizer class="table-page" show-total
					:total="pagination.total" :page-size="pagination.per_page" :current.sync="pagination.page"
					@on-change="getList" @on-page-size-change="changePageSizeNum" />
			</div>
			<div>
				<div><h2>{{ $t('添加提醒') }}</h2></div>
				<Col span="12">
					{{$t('日期')}}：<DatePicker type="date" v-model="reminder_info.date" :value="reminder_info.date" placeholder="Select date" style="width: 200px"></DatePicker>
					<span style="margin-left: 10px;">{{$t('印度时区')}}</span>
				</Col>
				<Col span="12" style="top: 20px;">
					{{$t('时间')}}：<TimePicker type="time" v-model="reminder_info.time" :value="reminder_info.time" format="HH:mm" placeholder="Select time" style="width: 200px"></TimePicker>
				</Col>
				<Col span="24" style="top:40px">
				{{$t('描述')}}：<Input style="margin: 8px 0; " v-model="reminder_info.describe" :value="reminder_info.describe" type="textarea" :autosize="{ minRows: 3, maxRows: 10 }"
					placeholder="Enter something..." />
				</Col>
				<Button style="margin: 50px 0;" type="error" @click="resetReminder">{{ $t('取消') }}</Button>
				<Button style="margin: 50px 20px;" type="primary" @click="handleSave">{{ $t('保存') }}</Button>
			</div>
		</div>

	</div>
</template>
<script>
import ManagerReminderApi from '@/api/manager-reminder'
// import noteEditRecords from './note-edit-records'
export default {
	// components: { noteEditRecords },
	props: {
		data: {
			type: Array,
		}
	},
	data() {
		return {
			columns: [
                {
                    type: 'index',
                    width: 60,
                    align: 'center'
                },
                {
                    title: this.$t('日期') + '-' + this.$t('时间'),
					width: 160,
                    key: 'reminder_time',
                    render: (h, { row }) => {
                        return h('span', this.$dayjs(row.reminder_time).format('YYYY-MM-DD HH:mm'))
                    }
                },
                {
                    title: this.$t('描述'),
					width: 180,
                    key: 'describe'
                },
                {
                    title: this.$t('状态'),
					width: 110,
                    // key: 'status_text',
					render: (h, { row }) => {
						return h('span', {
							style: {},
						}, this.$t(row.status_text))
					}
                },
                {
                    title: this.$t('操作'),
					width: 290,
                    align: 'center',
                    slot: 'action'
                }
            ],
			tableData: [],
            pagination: {
                total: 0,
                per_page: 10,
                page: 1
            },
			reminder_info:{
				'date': '',
				'time': '',
				'describe': '',
				'id': 0,
			},
		}
	},
	computed: {},
	created() {
        this.getList();
    },
	methods: {
		
		async getList() {
            try {
                this.loading = true
				let params = { per_page: this.pagination.per_page, page: this.pagination.page }
                const res = await ManagerReminderApi.getManagerReminderList(params)
                this.tableData = res.data
                this.pagination.total = res.meta.total
                this.pagination.page = res.meta.current_page
            } finally {
                this.loading = false
            }
        },
		changePageSizeNum(pageSize) {
			this.pagination.per_page = pageSize
			this.pagination.page = 1
			this.getList()
		},
		async handleDone(row){
			let params = {id: row.id}
			const result = await ManagerReminderApi.doneManagerReminder(params);
			if (result.code === 200) {
				this.$Message.success(this.$t('操作成功'))
				row.is_done = 1;
				row.status_text = this.$t('已完成');
			}
		},
		async handleDelete(index, row){
			let params = {id: row.id}
			this.$Modal.confirm({
				title: this.$t('删除确认'),
				content: `<p>${this.$t("您确定要删除此任务吗？")}</p>`,
				onOk: async () => {
					const result = await ManagerReminderApi.deleteManagerReminder(params);
					if (result.code === 200) {
						this.$Message.success(this.$t('操作成功'))
						row.is_delete = 1;
						this.tableData.splice(index, 1);
					}
				},
				onCancel: () => { }
			})
		},
		async handleReactive(row){
			let params = {id: row.id}
			const result = await ManagerReminderApi.reactiveManagerReminder(params);
			if (result.code === 200) {
				this.$Message.success(this.$t('操作成功'))
				row.is_done = 0;
				row.status_text = result.data.status_text;
				row.reminder_time = result.data.reminder_time;
			}
		},
		async handleUpdate(row){
			this.reminder_info.id = row.id;
			this.reminder_info.describe = row.describe;
			this.reminder_info.date = this.$dayjs(row.reminder_time).format('YYYY-MM-DD');
			this.reminder_info.time = this.$dayjs(row.reminder_time).format('HH:mm');
		},
		async handleSave() {
			let params = {
				id: this.reminder_info.id,
				describe: this.reminder_info.describe,
				date: this.reminder_info.date ? this.$dayjs(this.reminder_info.date).format('YYYY-MM-DD') : '',
				time: this.reminder_info.time,
			}
			let result = {};
			if(this.reminder_info.id === 0){
				result = await ManagerReminderApi.addManagerReminder(params);
			}else{
				result = await ManagerReminderApi.updateManagerReminder(params);
			}

			if (result.code === 200) {
				this.$Message.success(this.$t('修改成功'));
				this.getList();
			} else {
				this.$Message.error(result.msg);
			}
		},

		resetReminder(){
			this.reminder_info.id = 0;
			this.reminder_info.describe = '';
			this.reminder_info.date = '';
			this.reminder_info.time = '';
		},
		
	},
	mounted() { }
}
</script>
<style lang="less" scoped>
.reminder-details {
	width: 100%;
	height: 100%;
	padding: 14px;
	overflow-y: auto;
	position: relative;
	// background-color: #ccc;
}

.label {
	display: inline-block;
	width: 60px;
	font-size: 14px;
	font-weight: bold;
}

.list-style {
	width: 100%;
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	span {
		display: inline-block;
		width: 165px;
		font-size: 14px;
		line-height: 30px;
	}
}

:deep(.ivu-divider-horizontal) {
	margin: 14px 0;
}

.reminder-details::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.reminder-details::-webkit-scrollbar-track {
	background-color: #f8f8f8;
	-webkit-border-radius: 2em;
	-moz-border-radius: 2em;
	border-radius: 2em;
}

.member-details::-webkit-scrollbar-thumb {
	background-color: #dddddd;
	-webkit-border-radius: 2em;
	-moz-border-radius: 2em;
	border-radius: 2em;
}
</style>
