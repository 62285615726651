import fetch from '@/libs/fetch'

export default class ManagerReminderApi {
	// 提醒列表
	static getManagerReminderList(params) {
		return fetch({
			url: '/reminder/list',
			method: 'get',
			params
		})
	}
	// 添加提醒
	static addManagerReminder(params) {
		return fetch({
			url: `/reminder/add`,
			method: 'post',
			params
		})
	}
	// 提醒单条信息
	static managerRemindeInfo(params) {
		return fetch({
			url: `/reminder/info`,
			method: 'post',
			params
		})
	}
	// 修改提醒
	static updateManagerReminder(params) {
		return fetch({
			url: `/reminder/update`,
			method: 'post',
			params
		})
	}
	// 删除提醒
	static deleteManagerReminder(params) {
		return fetch({
			url: `/reminder/delete`,
			method: 'post',
			params
		})
	}
	// 完成提醒
	static doneManagerReminder(params) {
		return fetch({
			url: `/reminder/done`,
			method: 'post',
			params
		})
	}

	// 重新打开提醒
	static reactiveManagerReminder(params) {
		return fetch({
			url: `/reminder/reactive`,
			method: 'post',
			params
		})
	}
	// 即将到期提醒
	static notification() {
		return fetch({
			url: `/reminder/notification`,
			method: 'get',
		})
	}
}
