import axios from 'axios'
import config from '@/config'
import store from '@/store'
import { Notice } from 'view-design'
// import { getExpires } from '@/libs/utils'
import i18n from '@/locale'

const code = localStorage.getItem('code')
let baseURL = ''
if (code) {
	baseURL = config.baseUrl.pro[code].url
}
// const baseURL = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro[code]
// const baseURL = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : remote.getGlobal('shareAddress').url
const service = axios.create({
	baseURL: `${baseURL}api/v1/`,
	timeout: 120000,
	// baseURL: `${baseURL}api/v1/${localStorage.getItem('code')}`,
	headers: {
		// Accept: 'application/vnd.operationsystem.v1+json',
		// 'Content-Type': 'application/json'
	}
})

// request 拦截器
service.interceptors.request.use(
	(config) => {
		if (store.state.user.token) {
			config.headers['Authorization'] = `Bearer ${store.state.user.token}`
		}
		if (code) {
			config.headers['Platform-Code'] = code
		}
		config.params = {
			lang: i18n.locale,
			...config.params
		}
		return config
	},
	(error) => {
		Promise.reject(error)
	}
)

// response 拦截器
service.interceptors.response.use(
	async (response) => {
		if (response.status > 400 && response.status < 500) {
			Notice.error({
				title: i18n.t('错误信息'),
				desc: response.data.error || response.data.message,
				duration: 8
			})
			return Promise.reject(response.data)
		}
		return response.data
	},
	(error) => {
		const res = error.response
		// hook  下载文件出错提示信息
		if (res.data.type === 'application/json') {
			const reader = new FileReader()
			reader.onload = () => {
				const d = JSON.parse(reader.result)
				console.log(d)
				Notice.error({
					title: i18n.t('错误信息'),
					desc: d.message
				})
			}
			reader.readAsText(res.data)
		} else {
			Notice.error({
				title: i18n.t('错误信息'),
				desc: i18n.t(res.data.message || error.message)
			})
		}

		return Promise.reject(error)
	}
)

export default service
